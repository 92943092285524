<template>
  <div>
    <b-form
      novalidate
    >
      <b-alert
        show
        variant='warning'
        class='mb-2'
      >
        <div class='alert-body'>
          <h4 class='bold text-lg text-warning'>{{ $t('please_note') }}</h4>
          <p>{{ $t('messages.please_wait_the_confirm') }}</p>
        </div>
      </b-alert>

      <b-row class='match-height'>
        <b-col
          v-for='(item,index) in items'
          :key='index'
          md='6'
          lg='4'
        >
          <b-card no-body>
            <b-card-body>
              <b-card-title class='text-capitalize d-flex align-items-center'>
                {{ $t(item.title) }}
              </b-card-title>

              <b-card-text>
                <b-form-group>
                  <b-form
                    v-for='(child,label_index) in item.labels'
                    :key='label_index'
                    class='mb-1'
                  >
                    <p>
                      {{ $t(child.short_description) }}
                    </p>
                    <b-button
                      type='button'
                      variant='outline-primary mx-2'
                      @click.prevent='showUpdateModal(child.short_description,child.func_name,child.type)'
                    >
                      {{ $t('labels.update') }}
                    </b-button>
                  </b-form>
                </b-form-group>
              </b-card-text>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-form>

    <b-modal
      id='update-modal'
      ref='update-modal'
      ok-variant='warning'
      modal-class='modal-warning'
      centered
      :title="$t('messages.confirmation')"
    >
      <b-card-text>
        {{ modal_message }}
      </b-card-text>
      <template #modal-footer='{ cancel }'>
        <b-button @click='cancel()'>
          {{ $t('messages.cancel') }}
        </b-button>
        <b-button
          variant='warning'
          @click='dynamicModelFuncName'
        >
          {{ $t('messages.confirm') }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>
<script>
import formMixin from '@/mixins/formMixin'
import { BAlert } from 'bootstrap-vue'

export default {
  components: { BAlert },
  mixins: [formMixin],
  data() {
    return {
      modal_message: null,
      modal_confirm_function: null,
      action_type: null,
      items: [
        {
          'title': 'statistics',
          'labels': [
            {
              'type': 'teams-statistics',
              'name': 'teams_statistics',
              'short_description': 'teams_statistics',
              'func_name': 'updateCommands',
            },
            {
              'type': 'players-statistics',
              'name': 'players_statistics',
              'short_description': 'players_statistics',
              'func_name': 'updateCommands',
            },
          ],
        },
        {
          'title': 'transfers',
          'labels': [
            {
              'type': 'transfer-league',
              'name': 'transfer_league',
              'short_description': 'leagues_transfers_data',
              'func_name': 'updateCommands',
            },
            {
              'type': 'transfer-player',
              'name': 'transfer_player',
              'short_description': 'players_transfers_data',
              'func_name': 'updateCommands',
            },
          ],
        },
        {
          'title': 'lineups',
          'labels': [
            {
              'type': 'lineup',
              'name': 'lineups',
              'short_description': 'lineup_up_coming_matches',
              'func_name': 'updateCommands',
            },
          ],
        },
        {
          'title': 'standings',
          'labels': [
            {
              'type': 'standings',
              'name': 'standings',
              'short_description': 'standings_to_all_teams',
              'func_name': 'updateCommands',
            },
          ],
        },
        {
          'title': 'matches',
          'labels': [
            {
              'type': 'matches',
              'name': 'matches',
              'short_description': 'pull_matches_after_month_from_today',
              'func_name': 'updateCommands',
            },
          ],
        },
        {
          'title': 'h2h_matches',
          'labels': [
            {
              'type': 'h2h-matches',
              'name': 'h2h_matches',
              'short_description': 'h2h_matches_that_start_today_or_tomorrow',
              'func_name': 'updateCommands',
            },
          ],
        },
      ],
    }
  },
  computed: {
    dynamicModelFuncName() {
      return this[this.modal_confirm_function]
    },
  },

  methods: {
    showUpdateModal(type, funcName, action) {
      this.modal_message = this.$t('messages.are_you_sure_to_update') + ' ' + this.$t(type).toLowerCase() + ' ' + this.$t('question_mark')
      this.modal_confirm_function = funcName
      this.action_type = action
      this.$refs['update-modal'].show()
    },
    updateCommands() {
      this.axios.get('/update-commands?type=' + this.action_type)
        .then(() => {
          this.hideAndResetUpdateModal()

          this.$bvToast.toast(this.$t('messages.please_wait_the_confirm'), {
            title: this.$t('general.success'),
            variant: 'success',
            solid: true,
          })
        })
        .catch(() => {
          this.hideAndResetUpdateModal()

          this.$bvToast.toast(this.$t('errors.the_update_was_not_completed'), {
            title: this.$t('Error'),
            variant: 'danger',
            solid: true,
          })
        })
    },
    hideAndResetUpdateModal() {
      this.$refs['update-modal'].hide()
      this.modal_confirm_function = null
      this.modal_message = null
      this.action_type = null
    },
  },

}
</script>
<style lang='scss'>

</style>
